import React from "react"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Articles from "../components/fenetres/js/fenetres"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"
import Overlay from "../components/layout/js/overlay"

const CategoriesFenetres = () => {
  return (
    <>
      <SEO
        title="Vitrail pour fenêtre et verrières d’artiste"
        description="Retrouvez notre large sélection de vitraux pour fenêtres et verrières d’artiste. Pour plus d’informations, contact : 01 34 66 42 92"
      />
      <Header />
      <Overlay />
      <Articles />
      <Contacter />
      <Footer />
      <Copyrights />
    </>
  )
}

export default CategoriesFenetres
