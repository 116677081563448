import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../css/fenetres.module.css"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { document } from "browser-monads"
import { FormattedMessage } from "gatsby-plugin-intl"

const Fenetres = () => {
  const data = useStaticQuery(graphql`
    query {
      groupa: file(relativePath: { eq: "fenetres.json" }) {
        childDataJson {
          fenetres {
            collection
            montant
            nom
            image {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed
                }
                id
              }
            }
          }
        }
      }
      groupb: file(relativePath: { eq: "verrieres.json" }) {
        childDataJson {
          verrieres {
            collection
            montant
            nom
            image {
              childImageSharp {
                fixed(width: 350) {
                  ...GatsbyImageSharpFixed
                }
                id
              }
            }
          }
        }
      }
    }
  `)

  console.log(data)

  const [isFenetreChecked, setFenetreCheckbox] = useState(true)
  const [isVerriereChecked, setVerriereCheckbox] = useState(true)

  let tabFenetres = Array.from(document.getElementsByClassName("fenetre"))
  let tabVerrieres = Array.from(document.getElementsByClassName("verriere"))

  const handleChangeFenetre = event => {
    if (isFenetreChecked === true) {
      setFenetreCheckbox(false)
      tabFenetres = Array.from(document.getElementsByClassName("fenetre"))
      tabFenetres.forEach(fenetre => {
        fenetre.style.display = "none"
      })
    } else {
      setFenetreCheckbox(true)
      tabFenetres.forEach(fenetre => {
        fenetre.style.display = "block"
      })
    }
  }
  const handleChangeVerriere = event => {
    if (isVerriereChecked === true) {
      setVerriereCheckbox(false)
      tabVerrieres = Array.from(document.getElementsByClassName("verriere"))
      tabVerrieres.forEach(verriere => {
        verriere.style.display = "none"
      })
    } else {
      setVerriereCheckbox(true)
      tabVerrieres.forEach(verriere => {
        verriere.style.display = "block"
      })
    }
  }

  return (
    <section id="fenetres" className="first w-75-ns center">
      <h1 className={styles.titre}>
        <FormattedMessage id="window.title" />
      </h1>
      <p className="tc">
        Retrouvez notre large sélection de vitraux pour fenêtre et verrière
        d'artiste, ainsi que nos{" "}
        <Link to="/porte-entrees">vitrails porte d'entrée.</Link>
      </p>
      <div className={(styles.choix += " flex justify-center")}>
        <label className="mh2" htmlFor="fenetre">
          <input
            className="mr2"
            type="checkbox"
            name="fenêtre"
            id="fenetre"
            checked={isFenetreChecked}
            onChange={handleChangeFenetre}
          />
          <FormattedMessage id="window.check1" />
        </label>
        <label className="mh2" htmlFor="verriere">
          <input
            className="mr2"
            type="checkbox"
            name="verrière"
            id="verriere"
            checked={isVerriereChecked}
            onChange={handleChangeVerriere}
          />
          <FormattedMessage id="window.check2" />
        </label>
      </div>

      <div id="articles">
        <div className="flex flex-wrap justify-around mt6">
          {data.groupa.childDataJson.fenetres.map(fenetre => (
            <div
              key={fenetre.image.childImageSharp.id}
              className={(fenetre.montant += " w-100 w-50-ns w-33-l mv3")}
            >
              <Img
                fixed={fenetre.image.childImageSharp.fixed}
                style={{ display: "block", margin: "0 auto" }}
                alt={fenetre.nom}
              />
              <p className={styles.contenu} style={{ margin: "10px auto" }}>
                {fenetre.nom} <br />
                {fenetre.collection}
              </p>
            </div>
          ))}
        </div>
        <div className="flex flex-wrap justify-around mb6">
          {data.groupb.childDataJson.verrieres.map(verriere => (
            <div
              key={verriere.image.childImageSharp.id}
              className={(verriere.montant += " w-50-l mv3")}
            >
              <Img
                fixed={verriere.image.childImageSharp.fixed}
                style={{ display: "block", margin: "0 auto" }}
                alt={verriere.nom}
              />
              <p className={styles.contenu} style={{ margin: "10px auto" }}>
                {verriere.nom} <br />
                {verriere.collection}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Fenetres
